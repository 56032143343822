.app {
    display: flex;
    height: 100vh;
  }
  
  .content {
    flex-grow: 1;
    padding: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  