.sidebar {
  width: 250px;
  background-color: #f8f8f8;
  padding: 20px;
  border-right: 1px solid #ddd;
}

.sidebar h3 {
  margin-bottom: 20px;
}
.btn{
  text-decoration: none;
}

.sidebar button {
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  background-color: white;
  border: 1px solid #ccc;
  cursor: pointer;
  text-align: left;
  font-size: 16px;
  text-decoration: none;
  
}

.sidebar button:hover {
  background-color: #f0f0f0;
}
.form-preview-wrapper{
  margin-top: 160px;
}
