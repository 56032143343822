.form-editor {
  width: 100%;
  max-width: 280px; 
  padding: 28px;
  border: 1px solid #ddd;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-left: -330px; 
  height: 720px;
}


.form-editor h2 {
  margin-bottom: 20px;
  font-size: 1.5em;
  color: #333;
}

.form-editor label {
  display: block;
  margin: 10px 0 5px;
  font-weight: bold;
}

.form-editor input[type="text"],
.form-editor input[type="file"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.image-preview img {
  width: 100%;
  max-height: 150px;
  object-fit: cover;
  margin-bottom: 10px;
  border-radius: 4px;
}

.image-preview button {
  padding: 8px 15px;
  background-color: #ff0000;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.placement-toggle {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.placement-toggle button {
  padding: 10px 20px;
  border: 1px solid #ccc;
  cursor: pointer;
  border-radius: 4px;
  background-color: #f0f0f0;
  transition: background-color 0.3s;
}

.placement-toggle button:hover {
  background-color: #ddd;
}

.placement-toggle .active {
  background-color: #000;
  color: white;
}

.save-btn,
.discard-btn {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s, color 0.3s;
}

.save-btn {
  background-color: #000;
  color: white;
}

.save-btn:hover {
  background-color: #333;
}

.discard-btn {
  background-color: #ff0000;
  color: white;
}

.discard-btn:hover {
  background-color: #cc0000;
}
